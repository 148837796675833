<template>
  <div>
    <!-- Attachment -->
    <vx-card class="mb-base">
      <div class="vx-row">
          <div class="vx-col w-full">
            <h4>Attachment</h4>
            <div class="divider-line my-6"></div>
          </div>
      </div>
      <div v-if="item.attachment != null" style="max-height: 500px; overflow-y: scroll; overflow-x: hidden;">
        <div div class="vx-row mb-6 gap-y-3">
          <a
            :href="`${storage.learningCenter + data}`" 
            class="vx-col w-full sm:w-1/4 cursor-pointer" 
            v-for="data in item.attachment"
            :key="data.id"
            download>
            <div class="p-4 text-center" style="background: RGBA(0, 0, 0, .04); border-radius: 10px">
              {{ data }}
            </div>
          </a>
        </div>
      </div>

      <div class="flex" v-else>
          <div class="item w-full p-4" style="background: RGBA(0, 0, 0, .04); border-radius: 10px">
              Tidak ada file
          </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import moment from "moment";
import storage from "@/storage";
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import Quill from 'quill'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize';

window.Quill = require('quill');
Quill.register("modules/imageResize", ImageResize);

export default {
  components: {
    quillEditor
  },
  props: ['item'],
  data () {
    return {
      loaded: false,
      moment: moment,
      storage: storage,
      content: '',
      error: '',

      editorOption: {
        // some quill options
        modules: {
          toolbar: {
            container: [
              [{ 'size': [] }, { 'font': [] }],
              [{ 'color': [] }, { 'background': [] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'align': [] }, { 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
              ['link', 'image', 'blockquote', 'code-block']
              ]
          },
          imageResize: {
            modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
          }
        }
      },
    }
  },
  methods: {
    loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, Data will be displayed.`
      })
    },

    onLoaded () {
      this.$vs.loading.close();
      this.loaded = true;
    },
    getData() {
      this.id = this.$secure.decrypt(this.$route.params.id);
      this.$store.dispatch('learningCenter/detailItem', {id: this.id}).then(() => {
      this.onLoaded();
      }).catch(err => {
        if (!err.response) {
          // network error
          err.response = {
            data: {
              message: 'Server error'
            }
          };
        }

        this.$vs.notify({
          title:'Opps something error',
          text: err.data.msg_status,
          color:'danger'})
      });
    },
    saveData () {
      const self = this

      const formData = new FormData()
      formData.set('theory_id', self.item.id)
      formData.set('content', self.content)

      if(self.content != '') {
        this.$store.dispatch('learningCenter/addComment', {
          id: self.$secure.decrypt(this.$route.params.id),
          body: formData
        })
        .then(res => {
            self.$vs.notify({
                color: 'success',
                title: 'Success',
                text: res.data.msg_status,
            })
            self.resetForm()
            self.loading()
            self.getData()
        })
        .catch(err => {
            if (!err.response) {
                // network error
                err.response = {
                    data: {
                    message: 'Server error'
                    }
                };
            }
            this.$vs.notify({
            title:'Opps something error',
            text: err.response.data.message,
            color:'danger'})
        })
      } else {
        this.error = 'The content field is required.'
        setTimeout(() => {
            this.error = '';
          }, 3000);
      }
    },
    resetForm() {
      this.content = ''
    },
  },
}
</script>
<style>
    .ql-editor {
        min-height: 300px;
    }

    .divider-line {
        border-bottom: 2px solid #EDEDED;
        border-width: thin;
    }

    .divider {
        border-bottom: 2px dashed #DDDDDD;
        border-width: thin;
    }

    .vs-tabs--li {
        border: 1px solid #EDEDED;
        padding: 0 10px;
    }

    .activeChild {
        border: 1px solid rgba(var(--vs-primary), 0.4) !important;;
    }

    .vs__selected-options {
        padding: 4px
    }
</style>